.slick-prev:before,
.slick-next:before {
  color: #acc1ae;
}

.slick-dots {
  bottom: 15px;
}

.slick-dots li.slick-active button:before {
  color: #acc1ae;
  font-size: 10px;
}

#arrow-size:before {
  font-size: 35px;
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}
