@import "../../common-ui/vars.module.scss";

$QUANTITY_WIDTH: 160px;

.empty {
  text-align: center;
}

.goToShop {
  margin: $margin-4 auto;
}

.productList {
  margin-bottom: $margin-2;
}

.continueToShop {
  @include for-mobile-only {
    display: none;
  }
}

.product {
  text-align: center;
  margin-bottom: $margin-4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-big;
  @include for-mobile-only {
    flex-direction: column;
  }
  .productInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: calc(100% - #{$QUANTITY_WIDTH} - #{$margin-4});
    max-width: calc(100% - #{$QUANTITY_WIDTH} - #{$margin-4});
    @include for-mobile-only {
      min-width: 100%;
      max-width: 100%;
    }
    .productInfoLeft {
      display: flex;
      align-items: center;
      & > * {
        margin-right: $margin-2;
      }
      .logo {
        width: 50px;
        filter: drop-shadow(2px 4px 6px $color-5);
      }
      .description {
        text-align: left;
        .nickname {
          color: $gray;
          font-size: $font-size-small;
        }
        .preorderWarning {
          display: flex;
          color: $warning;
          font-size: $font-size-small;
          margin-top: $margin-1;
          img {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .quantity {
    display: flex;
    align-items: center;
    min-width: $QUANTITY_WIDTH;
    max-width: $QUANTITY_WIDTH;
    @include for-mobile-only {
      min-width: 100%;
      max-width: 100%;
      justify-content: center;
      margin-top: $margin-2;
    }
    & > *:nth-child(2) {
      margin-left: $margin-2;
      display: flex;
      img {
        width: 25px;
        cursor: pointer;
      }
    }
  }
}

.shipping {
  margin-bottom: $margin-2;
  font-size: $font-size-small;
  text-align: center;
}

.checkout {
  padding: $padding-2;
  border: 2px solid $color-4;
  box-sizing: border-box;
  margin-bottom: $margin-2;
  text-align: center;
}

.total {
  text-align: center;
  margin-bottom: $margin-2;
  .totalPrice {
    font-size: $font-size-big;
  }
  .oldTotalPrice {
    text-decoration: line-through $warning;
  }
}

.selectShippingRate {
  margin-bottom: $margin-2;
}

.warning {
  background: $color-2;
  margin-bottom: $margin-2;
  padding: $padding-1;
  border-radius: 5px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  img {
    width: 20px;
    margin-right: $margin-1;
  }
  b {
    margin: 0 4px;
  }
}

.promoContainer {
  display: flex;
  align-items: center;
  margin-bottom: $margin-2;
  button,
  input {
    margin: 0;
  }
  input {
    margin-right: $margin-2;
  }
}

.appliedPromotionCode {
  margin-bottom: $margin-2;
  font-size: $font-size-small;
}
