@import "../vars.module.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div:first-child {
    min-width: calc(70% - #{$margin-4});
    max-width: calc(70% - #{$margin-4});
  }
  & > div:last-child {
    min-width: 30%;
    max-width: 30%;
  }
  @include for-mobile-only {
    flex-direction: column;
    & > div:first-child {
      min-width: 100%;
      max-width: 100%;
    }
    & > div:last-child {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
