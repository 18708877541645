@import "../../common-ui/vars.module.scss";

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto $margin-4;
  @include for-mobile-only {
    width: 100%;
  }
  .label {
    text-align: left;
    margin-bottom: $margin-1;
    width: 100%;
  }
}

.button {
  margin-top: $margin-2;
}

.error {
  color: red;
  margin-bottom: $margin-2;
  text-align: center;
}
