@import "../common-ui/vars.module.scss";

.shopCta {
  margin-top: $margin-4;
}

.stripe {
  border-radius: 0;
  width: 40%;
  margin: auto;
  display: flex;
  @include for-mobile-only {
    margin-top: $margin-4;
  }
}
