@import "../vars.module.scss";

// Reference : https://codepen.io/mariusnohr/pen/xxMqGg

.container {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.burgermenu {
  border: none;
  background: none;
  width: 100%;
  height: 30px;
  position: absolute;
  padding: 0;
  outline: none;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid $black;
    transform: translateY(14px);
    transition: transform 0.5s ease, border-color 0.5s ease 0.3s;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $black;
      transform: translateY(-14px);
      animation-name: topLineBurger;
      animation-duration: 0.6s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $black;
      transform: translateY(14px);
      animation-name: bottomLineBurger;
      animation-duration: 0.6s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }
  }
}

.opened {
  span {
    border-color: transparent;
    transition: border-color 0.3s ease;
    &:before {
      animation-name: topLineCross;
      animation-duration: 0.6s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }
    &:after {
      animation-name: bottomLineCross;
      animation-duration: 0.6s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }
  }
}

@keyframes topLineCross {
  0% {
    transform: translateY(-14px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px) rotate(45deg);
  }
}

@keyframes bottomLineCross {
  0% {
    transform: translateY(14px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px) rotate(-45deg);
  }
}

@keyframes topLineBurger {
  0% {
    transform: translateY(0px) rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(-14px) rotate(0deg);
  }
}

@keyframes bottomLineBurger {
  0% {
    transform: translateY(0px) rotate(-45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(14px) rotate(0deg);
  }
}
