@import "../vars.module.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $margin-6 0;
  padding: $padding-6;
  border-top: 2px solid $color-4;

  &:first-child {
    margin-top: 0;
    border: none;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  & > div:first-child {
    min-width: calc(60% - #{$margin-2});
    max-width: calc(60% - #{$margin-2});
  }
  & > div:last-child {
    min-width: calc(40% - #{$margin-2});
    max-width: calc(40% - #{$margin-2});
  }
  &:last-child {
    margin-bottom: 0;
  }

  @include for-mobile-only {
    flex-direction: column;
    padding: $padding-6 0 0;
    margin: $margin-6 0;
    &:nth-child(2n) {
      flex-direction: column;
    }
    & > div:first-child,
    & > div:last-child {
      min-width: 100%;
      max-width: 100%;
    }
  }
  .image {
    width: 90%;
    // border-radius: 50%;
    margin: auto;
    display: flex;
    // @include box-shadow-1;
    filter: drop-shadow(2px 4px 6px #495457);
    @include for-mobile-only {
      margin-top: $margin-4;
      width: 70%;
    }
  }

  .content {
    & > div:first-child {
      text-align: center;
    }
    & > div:not(:first-child) {
      text-align: justify;
      margin-top: $margin-2;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    img {
      width: 50%;
      display: flex;
      margin: 0 auto 40px;
    }
  }
}
