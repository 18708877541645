@import "../../common-ui/vars.module.scss";

.name {
  font-size: $font-size-big;
  margin-bottom: $margin-2;
}

.description {
  color: $color-5;
  text-align: center;
}

.detail {
  margin-bottom: $margin-4;
  text-align: justify;
}

.detail2 {
  @extend .detail;
  margin: -20px 0 $margin-4 0;
}

.dimensions {
  margin-top: $margin-1;
}

.detailImage {
  width: 75%;
  margin: 0 auto $margin-4;
  display: flex;
  border-radius: 10px;
}

.ingredients {
  font-style: italic;
}

.conseil {
  .info {
    display: flex;
    align-items: center;
    margin-bottom: $margin-1;
    img {
      width: 20px;
      margin-right: $margin-1;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.imgContainer {
  img {
    width: 100%;
    margin: $margin-4 auto;
    display: flex;
    // filter: drop-shadow(2px 4px 6px $color-5);
  }
}

.preorderWarning {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: $warning;
  margin-bottom: $margin-2;
}

.back {
  color: $color-5;
  text-decoration: none;
  margin-bottom: $margin-2;
  display: block;
}
