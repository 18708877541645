@import "../../common-ui/vars.module.scss";

.logged {
  text-align: center;
  margin-bottom: $margin-4;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto $margin-2;
  width: $form-width;
  @include for-mobile-only {
    width: 100%;
  }
}

.buttons {
  width: $form-width;
  margin: auto;
  @include for-mobile-only {
    width: 100%;
  }
  button,
  a {
    width: 100%;
    margin-bottom: $margin-2;
  }
  .googleCta {
    background: $color-3;
    margin-top: $margin-2;
    &:hover {
      color: $black;
    }
    img {
      width: 30px;
      margin-right: $margin-1;
    }
  }
}

.error {
  color: red;
}
