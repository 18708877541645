@import "../../common-ui/vars.module.scss";

.logo {
  width: 120px;
  height: 120px;
  margin: auto;
  background-color: $color-4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  img {
    width: 80%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0) 75%
    );
  }
  @include box-shadow-1;
  @include for-mobile-only {
    display: none;
  }
}

.footer {
  font-size: $font-size;
  color: white;

  position: relative;
  background-color: $black;
  border-top: 2px solid $color-5;

  @include for-mobile-only {
    padding: $padding-2;
  }

  .container {
    width: $site-max-width;
    margin: auto;
    padding: $padding-4 $padding-2;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    @include for-mobile-only {
      width: 100%;
      flex-direction: column;
    }
    & > div {
      min-width: calc(100% / 4);
      max-width: calc(100% / 4);
      @include for-mobile-only {
        min-width: 100%;
        max-width: 100%;
      }
      &:not(:first-child) {
        div {
          @include for-mobile-only {
            margin-top: $margin-3;
          }
        }
      }
      div:not(:last-child) {
        font-weight: bold;
        margin-bottom: $margin-2;
        @include font-fantasy;
        font-size: $font-size-big;
      }
      a {
        width: fit-content;
        display: block;
        margin-bottom: $margin-1;
        text-decoration: none;
        color: white;
        font-size: $font-size-small;
        font-weight: thin;
        opacity: 0.6;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          opacity: 1;
        }
      }
      .contact {
        display: flex;
        align-items: center;
        font-weight: normal;
        margin-bottom: $margin-1;
        text-decoration: none;
        color: white;
        &:last-child {
          margin-bottom: 0;
        }
        .contactName {
          font-weight: normal;
          margin-bottom: 0;
          @include for-mobile-only {
            margin-top: 0;
          }
        }
        img {
          width: 20px;
          margin-right: $margin-1;
        }
      }
    }
  }
}
