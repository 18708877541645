@import "../../common-ui/vars.module.scss";

.title {
  font-size: $font-size-title;
  margin-bottom: $margin-4;
  margin-top: 0;
  color: $color-5;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-fantasy;
  @include for-mobile-only {
    text-align: center;
  }
}

.subtitle {
  justify-content: flex-start;
  color: $color-5;
  font-size: $font-size-bigger;
  margin-bottom: $margin-2;
  text-align: left;
}

.collectionName {
  text-transform: uppercase;
  font-size: $font-size-big;
  color: $color-5;
  padding: $padding-1 0;
  border-bottom: 3px solid $color-4;
}

.left {
  justify-content: flex-start;
}
