@import "../common-ui/vars.module.scss";

.container {
  a {
    margin: initial;
    margin: $margin-2 0 $margin-4;
  }
  .mail {
    font-weight: bold;
    margin-top: $margin-2;
  }
}
