@import "../vars.module.scss";

// In the component CountriesDelivery
$numberOfCountries: 13; // -> lenght of the array countries
$heightOfLine: 24px;

.infos {
  border: 2px solid $color-4;
  padding: $padding-2;
  color: $gray;
  .info {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    margin-bottom: $margin-1;
    img {
      width: 20px;
      margin-right: $margin-1;
    }
    &:last-child {
      margin: 0;
    }
    .subInfo a,
    .delivery {
      font-size: $font-size-smaller;
      color: $color-5;
      text-decoration: none;
      cursor: pointer;
      font-weight: bold;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.countryList {
  height: 0;
  margin-left: $margin-3;
  opacity: 0;
  overflow: hidden;
  transition-duration: 0.3s;
}

.isOpen {
  height: ($numberOfCountries * $heightOfLine) +
    ($numberOfCountries * $margin-1);
  opacity: 1;
}
