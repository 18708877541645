@import "../vars.module.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size;
}

.btn {
  background: $color-4;
  transition-duration: 0.3s;
  padding: $padding-1;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: $color-5;
    opacity: 0.7;
    transform: scale(1.1);
    color: #fff;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.value {
  font-weight: bold;
  padding: $padding-1;
  min-width: 40px;
  user-select: none;
}
