@import "../../common-ui/vars.module.scss";

.account {
  display: flex;
  gap: 30px;
  margin-top: $margin-2;
  @include for-mobile-only {
    flex-direction: column;
  }
}

.menu {
  width: 30%;
  @include for-mobile-only {
    width: 100%;
  }
  height: fit-content;
  border: 2px solid $color-4;
  .menuHeader {
    @include font-fantasy;
    font-size: $font-size-big;
    background-color: $color-4;
    padding: $padding-2;
    font-weight: bold;
  }
  .menuLinks {
    display: flex;
    flex-direction: column;
    padding: $padding-2;
    .link {
      color: $black;
      margin-bottom: $margin-3;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .selected {
      @extend .link;
      text-decoration: underline $color-4;
      text-underline-offset: 4px;
      &:hover {
        cursor: default;
        opacity: 1;
      }
    }
  }
}

.content {
  width: 70%;
  @include for-mobile-only {
    width: 100%;
  }
}
