@import "../../common-ui/vars.module.scss";

.banner {
  background-color: #8a0020;
  color: #fff;
  padding: $padding-1 $padding-4;
  text-align: center;
  position: fixed;
  top: $header-height;
  z-index: 1000;
  width: 100%;
  position: sticky;
  box-sizing: border-box;
}
