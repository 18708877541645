@import "../../common-ui/vars.module.scss";

.empty {
  text-align: center;
}

.goToShop {
  margin: $margin-4 auto;
}

.btn {
  margin: $margin-2 auto;
}

.product {
  text-align: center;
  padding: $padding-2 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include for-mobile-only {
    flex-direction: column;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $color-4;
  }
  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: $margin-2;
    }
    @include for-mobile-only {
      min-width: 100%;
      max-width: 100%;
      justify-content: center;
    }
    .logo {
      width: 50px;
      filter: drop-shadow(2px 4px 6px $color-5);
    }
    .description {
      text-align: left;
      .nickname {
        color: $gray;
        font-size: $font-size-small;
      }
      .preorderWarning {
        display: flex;
        color: $warning;
        font-size: $font-size-small;
        margin-top: $margin-1;
        img {
          margin-right: 4px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    @include for-mobile-only {
      margin-top: $margin-2;
    }

    .price {
      white-space: nowrap;
      margin-right: $margin-4;
    }

    .quantity {
      display: flex;
      align-items: center;
      & > *:nth-child(2) {
        margin-left: $margin-2;
        display: flex;
        img {
          width: 25px;
          cursor: pointer;
        }
      }
    }
  }
}

.editable {
  font-size: $font-size-big;
  @include for-mobile-only {
    .right .price {
      margin-right: $margin-2;
    }
  }
}

.shipping {
  margin-bottom: $margin-2;
  font-size: $font-size-small;
  text-align: center;
}

.checkout {
  padding: $padding-2;
  border: 2px solid $color-4;
  box-sizing: border-box;
  margin-bottom: $margin-2;
  text-align: center;
}

.total {
  text-align: center;
  margin-bottom: $margin-2;
  & > div {
    font-weight: bold;
    font-size: $font-size-big;
  }
}

.selectShippingRate {
  margin-bottom: $margin-2;
}
