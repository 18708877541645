@import "../vars.module.scss";

.textInput {
  max-height: $input-height;
  min-height: $input-height;
  box-sizing: border-box;
  border-radius: $border-radius;
  padding: 0 $padding-1;
  outline: none;
  font-size: 14px;
  border: 1px solid $color-3;
}

.marginRight {
  margin-right: $margin-2;
}

.marginBottom {
  margin-bottom: $margin-2;
}

.maxWidth {
  width: 100%;
}

.disabled {
  pointer-events: none;
  background: rgb(241, 241, 241);
  opacity: 0.6;
}
