@import "../../common-ui/vars.module.scss";

.selectInputs {
  .filters {
    min-width: max-content;
    display: flex;
    align-items: center;
  }
  display: flex;
  gap: $margin-2;
  max-width: 500px;
  justify-content: center;
  width: 100%;
  @include for-mobile-only {
    flex-direction: column;
  }
}

.products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include for-mobile-only {
    flex-direction: column;
  }
  & > a {
    width: calc((100% / 3) - #{$padding-6});
  }
  & > div {
    width: calc((100% / 3) - #{$padding-6});
    padding: 0 $padding-2;
  }
}

.collectionTitle {
  position: relative;
  text-align: center;
  margin: $margin-6 0 $margin-4;
  padding: $padding-3 0;
  border-top: 2px solid $color-5;
  border-bottom: 2px solid $color-5;
  font-size: 52px;
  @include for-mobile-only {
    font-size: 30px;
  }
}

.lotr {
  @include font-lotr;
  @include for-mobile-only {
    font-size: 30px;
  }
}

.hp {
  @include font-hp;
  font-size: 82px;
  @include for-mobile-only {
    font-size: 40px;
  }
}
