@import "../common-ui/vars.module.scss";

.page {
  width: 80%;
  margin: auto;
  box-sizing: border-box;
  @include for-mobile-only {
    width: 100%;
  }
}

.topContainer,
.bottomContainer {
  div {
    text-align: justify;
  }
  img {
    filter: drop-shadow(2px 4px 6px #495457);
  }
}

.topContainer {
  img {
    float: right;
    width: 50%;
    margin-left: $margin-3;
    @include for-mobile-only {
      display: block;
      float: none;
      width: 80%;
      margin: 0 auto $margin-2 auto;
    }
  }
  div:not(:last-child) {
    margin-bottom: $margin-1;
    @include for-mobile-only {
      margin-bottom: $margin-2;
    }
  }
}

.bottomContainer {
  img {
    float: left;
    width: 29.5%;
    margin-right: $margin-3;
    @include for-mobile-only {
      display: block;
      float: none;
      width: 60%;
      margin: 0 auto $margin-2 auto;
    }
  }
  div:not(:last-child) {
    margin-bottom: $margin-2;
  }
}

.postScriptum {
  margin-top: $margin-4;
  font-weight: bold;
  a {
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
}

.quote {
  background-color: hsl(0, 0%, 93%);
  margin: $margin-3;
  padding: $padding-1 $padding-1 $padding-1 $padding-3;
  border-left: 4px solid $black;
  @include for-mobile-only {
    margin: $margin-3 0;
    padding: $padding-1 $padding-1 $padding-1 $padding-1;
  }
}

.instagram {
  background: -webkit-linear-gradient(
    0deg,
    rgba(245, 190, 93, 1) 0%,
    rgba(206, 45, 114, 1) 10%,
    rgba(87, 86, 197, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.pinterest {
  color: #e60023;
}
