@import "../../common-ui/vars.module.scss";

.banner {
  background-color: $color-2;
  display: flex;
  justify-content: center;
  padding: $padding-4 $padding-8;
  background-image: url("../../../assets/images/homepage_banner_2.jpg");
  background-size: 100%;
  background-position: 0px -60px;
  color: #fff;
  position: relative;

  min-height: 85vh;
  box-sizing: border-box;

  @include for-mobile-only {
    padding: $padding-4;
    background-size: cover;
    min-height: auto;
  }

  .overlay {
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-right: 40%;
    @include font-fantasy;

    @include for-mobile-only {
      padding-right: 0;
    }

    .title {
      font-size: 50px;
      @include font-fantasy;
      margin: 0;
      margin-bottom: $margin-2;
      font-weight: 500;
    }

    .description {
      font-size: 35px;
      margin: $margin-5 0;
      text-align: center;
      padding: 0 $padding-8;
      @include for-mobile-only {
        padding: 0;
        font-size: $font-size-big;
      }
    }

    .logo {
      width: 300px;
    }
  }
}
