@import "../vars.module.scss";

.button {
  min-height: $button-height;
  max-height: $button-height;
  outline: none;
  padding: 0 $padding-2;
  border: none;
  background: $color-4;
  font-size: $font-size-small;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-family: "RobotoLight";
  min-width: 120px;
  box-sizing: border-box;
  margin: auto;
  color: $black;
  transition: all 0.2s ease;
  @include font;
  cursor: pointer;
  &:hover {
    background: $color-5;
    opacity: 0.7;
    transform: scale(1.1);
    color: #fff;
  }
}

.secondary {
  background: #fff;
  border: 1px solid $color-3;
  box-sizing: border-box;
  min-width: 100px;
  border-radius: 5px;
  &:hover {
    background: #fff;
    color: $black;
  }
}

.invert {
  background: none;
  color: $color-5;
  &:hover {
    background: none;
    color: $color-5;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selected {
  transform: scale(0.9);
  opacity: 0.8;
}

.hidden {
  color: $color-2;
}

.maxWidth {
  width: 100%;
}

.loader {
  position: absolute;
}

.link {
  background: none;
  &:hover {
    background: none;
    color: $black;
  }
}
