@import "../../common-ui/vars.module.scss";

.toasts {
  position: absolute;
}

:global(.Toastify__toast) {
  min-height: 50px;
  padding: 8px 16px;
  @include font;
  font-size: $font-size-small;
}

:global(.Toastify__toast-container--top-right) {
  top: 140px;
  right: 16px;
}

:global(.Toastify__progress-bar) {
  height: 3px;
}

:global(.Toastify__toast-icon) {
  display: none;
}

:global(.Toastify__toast--success) {
  background: $color-4;
  color: $color-5;
}
:global(.Toastify__toast--warning) {
  background: red;
  color: $color-5;
}

:global(.Toastify__toast--error) {
  background: $color-1;
  color: $black;
}

:global(.Toastify__progress-bar--success) {
  background: $color-5;
}

:global(.Toastify__close-button--success) {
  color: $color-5;
}
