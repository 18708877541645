@import "../../common-ui/vars.module.scss";

.productCard {
  position: relative;
  padding-top: $padding-2;
  text-align: center;
  margin-bottom: $margin-4;
  min-width: calc((100% / 2) - #{$margin-2});
  max-width: calc((100% / 2) - #{$margin-2});
  box-sizing: border-box;
  color: $black;
  text-decoration: none;
  transition-duration: 0.3s;
  overflow: hidden;

  @include for-mobile-only {
    min-width: 100%;
    max-width: 100%;
    &:not(:last-child) {
      padding-bottom: $padding-5;
      border-bottom: 1px solid $color-5;
    }
  }
  &:hover {
    img {
      transform: scale(0.97);
    }
  }
  .name {
    font-size: $font-size-bigger;
    padding: 0 $padding-2;
    @include font-fantasy;
  }
  .description {
    color: $color-5;
    padding: 0 $padding-2;
    line-height: 1.2em;
    min-height: 2.4em;
  }
  .tag {
    width: fit-content;
    min-width: 100px;
    margin: auto;
    padding: 4px $padding-1;
    color: white;
    font-size: $font-size-small;
    font-weight: bold;
    background-color: $color-1;
    border-radius: 20px;
    @include for-mobile-only {
      margin-top: $margin-1;
    }
  }
  .soldOut {
    background-color: $warning;
  }
  .productImg {
    max-width: 100%;
    margin-bottom: $margin-2;
    transition-duration: 0.3s;
    vertical-align: middle;
  }
  .price {
    color: $color-5;
    margin-top: $margin-2;
    .old {
      text-decoration: line-through $warning;
      margin-right: 5px;
    }
    .new {
      color: $warning;
      font-weight: bold;
    }
  }

  .preorderTriangle {
    position: absolute;
    bottom: -2px;
    right: -2px;
    border-style: solid;
    border-width: 40px;
    border-color: transparent $color-1 $color-1 transparent;
  }
  .newProductTriangle {
    @extend .preorderTriangle;
    border-color: transparent $color-3 $color-3 transparent;
  }
}

.btn {
  margin-top: $margin-2;
}
