@import "../common-ui/vars.module.scss";

.page > div {
  margin-bottom: $margin-2;
  a {
    color: $color-4;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      opacity: 0.8;
    }
  }
}

.stripe {
  width: 200px;
  margin: auto;
  display: flex;
}

.link {
  // To extend with .page div a for avoid repetition
  color: $color-4;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
  }
}

.logoLabels {
  width: 200px;
  margin-top: $margin-1;
}
