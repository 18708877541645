@import "../vars.module.scss";

.page {
  animation-name: animation-fade;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  opacity: 0;

  margin: 0 auto;
  padding: $padding-5 $padding-2;
  max-width: $site-max-width;
}

@keyframes animation-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
