@import "../common-ui/vars.module.scss";

.description {
  margin-bottom: $margin-3;
}

.year {
  font-size: $font-size-title;
  @include font-fantasy;
  color: $color-5;
}

.event {
  margin: $margin-5 $margin-2;

  .name {
    font-size: $font-size-bigger;
    @include font-fantasy;
  }

  .images {
    display: flex;
    gap: $margin-2;
    margin-top: $margin-2;
    img {
      max-width: 500px;
    }

    @include for-mobile-only {
      flex-direction: column;

      img {
        width: 100%;
      }
    }
  }
}
