@import "../../../common-ui/vars.module.scss";

.mobile {
  @include for-mobile-only {
    display: block;
  }
  @include for-desktop-only {
    display: none;
  }
}

.desktop {
  @include for-mobile-only {
    display: none;
  }
  @include for-desktop-only {
    display: block;
  }
}

.title {
  font-size: $font-size-big;
  margin-bottom: $margin-2;
}

.priceList {
  margin-bottom: $margin-2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  .format {
    cursor: pointer;
    padding: $padding-1;
    border: 1px solid transparent;
    position: relative;
    img {
      width: 50px;
      height: 50px;
    }
    .priceName {
      font-size: $font-size-small;
    }
    &:hover {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 2px;
        background-color: $color-1;
        transform: translate(-50%);
        animation-name: sub;
        animation-duration: 0.5s;
      }
    }
  }
  .selectedFormat {
    cursor: default;
    border: 1px solid $color-1;
    .priceName {
      color: $warning;
    }
    &:hover {
      &::after {
        content: none;
      }
    }
  }
}

@keyframes sub {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.checkout {
  border: 2px solid $color-4;
  padding: $padding-2;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: $margin-2;
  &:last-child {
    margin-bottom: 0;
  }
  .description {
    margin: $margin-2 0;
  }
  .price {
    font-size: $font-size-big;
    .discount {
      color: $black;
      text-decoration: line-through $warning 2px;
      margin-bottom: 4px;
    }
  }
  .priceDiscount {
    color: $warning;
  }
  .stock {
    color: $color-5;
    margin: $margin-2 0;
    &:before {
      content: "\A";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: rgb(0, 190, 0);
      display: inline-block;
      margin-right: 5px;
    }
  }
  button,
  a {
    margin-top: $margin-2;
  }
  .soldLimited {
    &:before {
      background: rgb(255, 149, 0);
    }
  }
  .soldOut {
    margin-bottom: 0;
    color: $gray;
    &:before {
      background: red;
    }
  }
}
