@import "../../common-ui/vars.module.scss";

.empty {
  text-align: center;
}

.order {
  border-bottom: 1px solid $color-4;
  &:last-child {
    border: none;
  }
}

.payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding-2 0;
  font-weight: bold;
  cursor: pointer;
}

.chevron {
  min-width: 32px;
  min-height: 32px;
  background-image: url("../../../assets/icons/circle-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  @include for-mobile-only {
    min-width: 24px;
    min-height: 24px;
  }
}
.chevronReverse {
  @extend .chevron;
  transform: rotateZ(180deg);
}

.loader {
  margin: $margin-2 0;
}

.itemList {
  border-top: 1px solid $color-4;
}
