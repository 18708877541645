// RESPONSIVE

$responsive-width: 1024px;

@mixin for-mobile-only {
  @media only screen and (max-width: $responsive-width) {
    @content;
  }
  @media only screen and (max-width: ($responsive-width * 2)) and (min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin for-desktop-only {
  @media only screen and (min-width: $responsive-width) {
    @content;
  }
  @media only screen and (min-width: ($responsive-width * 2)) and (min-device-pixel-ratio: 2) {
    @content;
  }
}

// MARGIN / PADDING

$margin-1: 10px;
$margin-2: 20px;
$margin-3: 30px;
$margin-4: 40px;
$margin-5: 50px;
$margin-6: 60px;
$margin-7: 70px;
$margin-8: 80px;

$padding-1: 10px;
$padding-2: 20px;
$padding-3: 30px;
$padding-4: 40px;
$padding-5: 50px;
$padding-6: 60px;
$padding-7: 70px;
$padding-8: 80px;

// FONT

$font-size-smaller: 14px;
$font-size-small: 16px;
$font-size: 18px;
$font-size-big: 24px;
$font-size-bigger: 30px;
$font-size-title: 40px;

@mixin font {
  font-family: "LouisGeorgeCafe", sans-serif;
}

@mixin font-fantasy {
  font-family: "dumbledor", sans-serif;
}

@mixin font-lotr {
  font-family: "RingABear", sans-serif;
}

@mixin font-hp {
  font-family: "HarryPotter", sans-serif;
}

// COLORS

$color-1: #eba9aa;
$color-2: #f5dccf;
// $color-3: #dad7cd;
$color-3: #ecebe7;
$color-4: #acc1ae;
$color-5: #495457;

$background-color: $color-3;
$warning: #e17f81;

$black: #393939;
$gray: gray;

// OTHER

$border-radius: 8px;

$input-height: 40px;
$button-height: 40px;

$app-max-width: 1000px;

$border-radius: 5px;

$site-max-width: 1200px;

$header-height: 80px;
$footer-height: 150px;

$form-width: 400px;

@mixin box-shadow-1($a: 2px, $b: 2px, $c: 10px) {
  box-shadow: $a $b $c grey;
}
