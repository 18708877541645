@import '../vars.module.scss';

.line {
	height: 24px;
	display: flex;
	font-size: $font-size-small;
	&:first-child {
		margin-top: $margin-1;
	}
	&:not(:last-child) {
		margin-bottom: $margin-1;
	}
	img {
		margin-right: $margin-1;
	}
}
