@import "../../common-ui/vars.module.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-top: $margin-8;
  a {
    text-decoration: none;
  }
  img {
    width: 62px;
    height: 62px;
    opacity: 0.6;
  }
}

.instagram {
  background: -webkit-linear-gradient(
    0deg,
    rgba(245, 190, 93, 1) 0%,
    rgba(206, 45, 114, 1) 10%,
    rgba(87, 86, 197, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  &:hover {
    opacity: 0.8;
  }
}

.pinterest {
  color: #e60023;
  &:hover {
    opacity: 0.6;
  }
}
