@import "../modules/common-ui/vars.module.scss";

@font-face {
  font-family: "stonehen";
  src: url("../assets/fonts/stonehen.ttf");
}

@font-face {
  font-family: "dumbledor";
  src: url("../assets/fonts/dumbledor.ttf");
}

@font-face {
  font-family: "Hobbiton";
  src: url("../assets/fonts/Hobbiton.ttf");
}

@font-face {
  font-family: "LouisGeorgeCafeLight";
  src: url("../assets/fonts/LouisGeorgeCafeLight.ttf");
}

@font-face {
  font-family: "LouisGeorgeCafe";
  src: url("../assets/fonts/LouisGeorgeCafe.ttf");
}

@font-face {
  font-family: "LouisGeorgeCafeBold";
  src: url("../assets/fonts/LouisGeorgeCafeBold.ttf");
}

@font-face {
  font-family: "Ringabear";
  src: url("../assets/fonts/Ringabear.ttf");
}

@font-face {
  font-family: "HarryPotter";
  src: url("../assets/fonts/HarryPotter.ttf");
}

body {
  margin: 0;
  @include font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size;
  color: $black;
  background: $background-color;
}
