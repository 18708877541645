@import "../vars.module.scss";

.container {
  margin-bottom: $margin-2;
  border-bottom: 1px solid $color-4;
  padding-bottom: $padding-2;
  .title {
    font-size: $font-size-bigger;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @include font-fantasy;
    @include for-mobile-only {
      font-size: $font-size-big;
    }
    .chevron {
      min-width: 32px;
      min-height: 32px;
      background-image: url("../../../assets/icons/circle-arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      @include for-mobile-only {
        min-width: 24px;
        min-height: 24px;
      }
    }
    .chevronReverse {
      @extend .chevron;
      transform: rotateZ(180deg);
    }
  }
  .description {
    max-height: 0;
    transition-duration: 0.3s;
    overflow: hidden;
    opacity: 0;
    text-align: justify;
  }
  .isOpen {
    margin-top: $margin-2;
    max-height: 300px;
    opacity: 1;
  }
}
