@import "../../common-ui/vars.module.scss";

.banner {
  padding: $padding-2;
  background-color: $color-2;
  text-align: center;
  margin-bottom: $margin-4;
  div {
    margin-bottom: $margin-1;
  }
  animation: animation;
  animation-duration: 1.5s;
  transform-origin: top center;
  overflow: hidden;
}

@keyframes animation {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 300px;
    opacity: 1;
  }
}
