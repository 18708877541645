@import "../../common-ui/vars.module.scss";

.selectInput {
  width: 100%;
  height: 40px;
  // border: 1px solid $color-4;
  // background-color: transparent;

  background-color: #fff;
  border-radius: $border-radius;
  border: none;
  font-size: 14px;
  padding: 0 15px;
  float: none;
  font-family: RobotoLight, sans-serif;
  outline: none;
  white-space: normal;
}

.disabled {
  transition-duration: 0.3s;
  opacity: 0.3;
  pointer-events: none;
  background: #525252;
}
