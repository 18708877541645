@import "../../common-ui/vars.module.scss";

.header {
  background: $color-4;
  @include font-fantasy;
  font-size: $font-size-big;
  padding: 0 $padding-4;
  position: sticky;
  top: 0;
  z-index: 10000;
  height: $header-height;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // Removed for christmas banner
  // box-shadow: 0 0 10px $gray;
  @include for-mobile-only {
    padding: 0 $padding-1;
  }
  .container {
    width: $site-max-width;
    margin: auto;
    padding: 0 $padding-2;
    box-sizing: border-box;
    @include for-mobile-only {
      width: 100%;
    }
  }
  .menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
    }
    & > div:first-child {
      .logo {
        width: 90px;
      }
    }
  }
  a {
    color: $black;
    margin-right: $margin-2;
    text-decoration: none;
    &:last-child {
      margin-right: 0;
    }
  }

  img {
    width: 30px;
  }
  .avatar {
    border-radius: 50%;
  }
  .cart {
    position: relative;
    margin: 0;
    height: 30px;
    .nb {
      position: absolute;
      top: 2px;
      right: -10px;
      background: $color-5;
      padding: 2px;
      color: #fff;
      font-size: 15px;
      border-radius: 50%;
      min-width: 20px;
      min-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font;
    }
  }
}

.desktopOption {
  display: flex;
  align-items: center;
  img {
    margin-right: $margin-1;
  }
  @include for-mobile-only {
    display: none;
  }
}

.mobileMenu {
  margin-left: $margin-2;
  & > div:first-child {
    margin-left: $margin-1;
  }
  @include for-desktop-only {
    display: none;
  }
  .mobileMenuContainer {
    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    transition-duration: 0.5s;
    display: flex;
    flex-direction: column;
    & > a {
      width: 100%;
      height: 60px;
      background: $color-3;
      padding: 0 $padding-2;
      color: $black;
      border-bottom: 1px solid $black;
      display: flex;
      align-items: center;
      img {
        margin-right: $margin-1;
      }
    }
  }
  .opened {
    height: calc(100vh - #{$header-height});
    opacity: 1;
  }
}
