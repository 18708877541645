@import "../../common-ui/vars.module.scss";

.button {
  margin-top: 15px;
  font-size: $font-size;
}

.closeButton {
  width: 24px;
  height: 24px;
  margin-right: -$margin-1;
  opacity: 0.4;
  cursor: pointer;
  &:hover {
    opacity: 1;
    transition: all 0.2s ease;
  }
}
